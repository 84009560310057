import React from 'react';
import PozegnanieLata2022 from './2022/PozegnanieLata2022';
import PozegnanieLata2022Bgc from '../../assets/images/gallery/2022/lato2022/logo.png';
import CzapaI2022 from './2022/CzapaI';
import CzapaI2022Bgc from '../../assets/images/gallery/2022/czapaI/logo.jpg';
import CzapaII2022 from './2022/CzapaII';
import CzapaII2022Bgc from '../../assets/images/gallery/2022/czapaII/logo.png';
import CzapaIII2022 from './2022/CzapaIII';
import CzapaIII2022Bgc from '../../assets/images/gallery/2022/czapaIII/logo.png';
import PrimaMethoda2023 from './2023/PrimaMethoda2023';
import PrimaMethoda2023Bgc from '../../assets/images/gallery/2023/primaMethoda/logo.png';
import Trotka2023 from './2023/TrotkaMeeting2023';
import Trotka2023Bgc from '../../assets/images/gallery/2023/trotka/logo.png';
import Meus2023 from './2023/Meus';
import Meus2023Bgc from '../../assets/images/gallery/2023/meus2023/logo.png';
import BarcinNight2023 from './2023/PucharBarcin';
import BarcinNight2023Bgc from '../../assets/images/gallery/2023/barcinNight2023/logo.png';
import Parowe2023 from './2023/ZawodyParowe';
import Parowe2023Bgc from '../../assets/images/gallery/2023/parowe2023/logo.png';
import PozegnanieLata2023 from './2023/PozegnanieLata';
import PozegnanieLata2023Bgc from '../../assets/images/gallery/2023/pozegnanieLata2023/logo.png';
import StartSezonu2024 from './2024/StartSezonu';
import startSezonu2024Bgc from '../../assets/images/gallery/2024/startSezonu/logo.jpg';
import KoniecSezonu2024 from './2024/KoniecSezonu';
import KoniecSezonu2024Bgc from '../../assets/images/gallery/2024/zakonczenieSezonu/logo.jpg';

const EventList = () => {
  // new item
  // {
  //     title: '',
  //     date: '',
  //     backgroundImage: ,
  //     path: '',
  //     page: < />,
  //   },

  const eventsList = [
    {
      title: 'Koniec Sezonu 2024',
      date: '28.09.2024',
      backgroundImage: KoniecSezonu2024Bgc,
      path: 'zakończenieSezonu2024',
      page: <KoniecSezonu2024 />,
    },
    {
      title: 'Start Sezonu 2024',
      date: '7.04.2024',
      backgroundImage: startSezonu2024Bgc,
      path: 'startSezonu2024',
      page: <StartSezonu2024 />,
    },
    {
      title: 'Pożegnanie Lata',
      date: '30.09.2023',
      backgroundImage: PozegnanieLata2023Bgc,
      path: 'pozegnanieLata2023',
      page: <PozegnanieLata2023 />,
    },
    {
      title: 'Zawody parowe',
      date: '20.08.2023',
      backgroundImage: Parowe2023Bgc,
      path: 'parowe2023',
      page: <Parowe2023 />,
    },
    {
      title: 'Puchar łowiska Barcin',
      date: '22/23.07.2023',
      backgroundImage: BarcinNight2023Bgc,
      path: 'pucharBarcin2023',
      page: <BarcinNight2023 />,
    },
    {
      title: 'MEUS',
      date: '26/27.05.2023',
      backgroundImage: Meus2023Bgc,
      path: 'meus2023',
      page: <Meus2023 />,
    },
    {
      title: 'Trotka Meeting',
      date: '7.05.2023',
      backgroundImage: Trotka2023Bgc,
      path: 'trotka2023',
      page: <Trotka2023 />,
    },
    {
      title: 'Prima Methoda',
      date: '1.04.2023',
      backgroundImage: PrimaMethoda2023Bgc,
      path: 'primaMethoda2023',
      page: <PrimaMethoda2023 />,
    },
    {
      title: 'Czapa III',
      date: '13.11.2022',
      backgroundImage: CzapaIII2022Bgc,
      path: 'czapaIII2022',
      page: <CzapaIII2022 />,
    },
    {
      title: 'Czapa II',
      date: '23.10.2022',
      backgroundImage: CzapaII2022Bgc,
      path: 'czapaII2022',
      page: <CzapaII2022 />,
    },
    {
      title: 'Czapa I',
      date: '15.10.2022',
      backgroundImage: CzapaI2022Bgc,
      path: 'czapaI2022',
      page: <CzapaI2022 />,
    },
    {
      title: 'Pożegnanie Lata',
      date: '01.10.2022',
      backgroundImage: PozegnanieLata2022Bgc,
      path: 'pozegnanie2022',
      page: <PozegnanieLata2022 />,
    },
  ];
  return { eventsList };
};

export default EventList;
