const CupResults = () => {
  const players = [
    {
      name: 'Dawid Rogalski',
      startSezon: 33.66,
      springCup: 30.74,
      pairCup: 18.72,
      nightCup: 34.27,
      summerBye: 3.52,
    },
    {
      name: 'Grzegorz Hebel',
      startSezon: 25.4,
      springCup: 4.4,
      pairCup: 21.09,
      nightCup: 49.35,
      summerBye: 8.65,
    },
    {
      name: 'Piotr Jackowski',
      startSezon: 19.43,
      springCup: 36,
      pairCup: 43.9,
      nightCup: 84.93,
      summerBye: 28.22,
    },
    {
      name: 'Michał Stolp',
      startSezon: 12.05,
      springCup: 27.07,
      pairCup: 0,
      nightCup: 21.17,
      summerBye: 9.05,
    },
    {
      name: 'Paweł Bartkowiak',
      startSezon: 8.47,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Filip Ziółkiewicz',
      startSezon: 4.77,
      springCup: 15.24,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Jacek Walter ',
      startSezon: 4.72,
      springCup: 13.7,
      pairCup: 15.24,
      nightCup: 36.1,
      summerBye: 4.81,
    },
    {
      name: 'Szymon Urbanowski ',
      startSezon: 3.72,
      springCup: 0,
      pairCup: 5.21,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Damian Wilma ',
      startSezon: 13.38,
      springCup: 13.52,
      pairCup: 56.97,
      nightCup: 45.44,
      summerBye: 0,
    },
    {
      name: 'Dariusz Brauer',
      startSezon: 13.29,
      springCup: 0,
      pairCup: 17.13,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Wasyl Semirga',
      startSezon: 10.29,
      springCup: 34.7,
      pairCup: 18.4,
      nightCup: 0,
      summerBye: 14.21,
    },
    {
      name: 'Leszek Leppert ',
      startSezon: 8.37,
      springCup: 22.79,
      pairCup: 0,
      nightCup: 0,
      summerBye: 21.52,
    },
    {
      name: 'Robert Stryczyński ',
      startSezon: 5.55,
      springCup: 9.15,
      pairCup: 2.63,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Janusz Waleńczak',
      startSezon: 3.65,
      springCup: 0,
      pairCup: 0,
      nightCup: 22.7,
      summerBye: 8.04,
    },
    {
      name: 'Przemysław Wieczorek',
      startSezon: 3.5,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 6.22,
    },
    {
      name: 'Mariusz Gwiazda ',
      startSezon: 2.83,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Hubert Mizera ',
      startSezon: 13.01,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 15.5,
    },
    {
      name: 'Grzegorz Rewoliński',
      startSezon: 10,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Mateusz Mikulski',
      startSezon: 7.59,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Bartosz Maludziński ',
      startSezon: 0,
      springCup: 26.84,
      pairCup: 16.36,
      nightCup: 34.45,
      summerBye: 29.27,
    },
    {
      name: 'Krzysztof Borlik ',
      startSezon: 2.1,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 12.05,
    },
    {
      name: 'Wojtek Vahe Avagyan ',
      startSezon: 1.91,
      springCup: 0,
      pairCup: 22.84,
      nightCup: 7.17,
      summerBye: 0,
    },
    {
      name: 'Grzegorz Kapusta',
      startSezon: 1.75,
      springCup: 37.03,
      pairCup: 0,
      nightCup: 0,
      summerBye: 8.27,
    },
    {
      name: 'Przemysław Lenartowicz',
      startSezon: 1.19,
      springCup: 2.98,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Bartłomiej Kościelniak',
      startSezon: 7.66,
      springCup: 57.64,
      pairCup: 36.84,
      nightCup: 63.02,
      summerBye: 29.45,
    },
    {
      name: 'Mateusz Drożdżowski',
      startSezon: 6.61,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Marcin Kolasa ',
      startSezon: 5.98,
      springCup: 5.9,
      pairCup: 14.68,
      nightCup: 35.2,
      summerBye: 0,
    },
    {
      name: 'Patryk Wieczorek ',
      startSezon: 3.029,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 15.48,
    },
    {
      name: 'Miłosz Srebny',
      startSezon: 1.57,
      springCup: 18.33,
      pairCup: 44.5,
      nightCup: 0,
      summerBye: 37.05,
    },
    {
      name: 'Patryk Kępa',
      startSezon: 1.2,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Sylwester Kolassa',
      startSezon: 1.16,
      springCup: 0,
      pairCup: 0,
      nightCup: 23.22,
      summerBye: 0,
    },
    {
      name: 'Andrzej Fabijański ',
      startSezon: 4.3,
      springCup: 38.19,
      pairCup: 27.84,
      nightCup: 15.72,
      summerBye: 3.7,
    },
    {
      name: 'Paweł Ćwikowski',
      startSezon: 1.9,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Dariusz Bogoński',
      startSezon: 1.048,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Łukasz Wołujewicz',
      startSezon: 1,
      springCup: 5.22,
      pairCup: 0,
      nightCup: 13,
      summerBye: 2.66,
    },
    {
      name: 'Mateusz Rybka',
      startSezon: 0.93,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Sebastian Malinowski ',
      startSezon: 0,
      springCup: 7,
      pairCup: 7,
      nightCup: 4.2,
      summerBye: 1.5,
    },
    {
      name: 'Adam Rubaszewski ',
      startSezon: 0,
      springCup: 14.67,
      pairCup: 0,
      nightCup: 0,
      summerBye: 9.42,
    },
    {
      name: 'Dariusz Kwieciński',
      startSezon: 0,
      springCup: 0,
      pairCup: 0,
      nightCup: 10.73,
      summerBye: 7.91,
    },
    {
      name: 'Marek Kiełczewski',
      startSezon: 0,
      springCup: 34.03,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Seweryn Kwieciński',
      startSezon: 0,
      springCup: 28.96,
      pairCup: 0,
      nightCup: 0,
      summerBye: 10.26,
    },
    {
      name: 'Krzysztof Kęsy',
      startSezon: 0,
      springCup: 27.39,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Tomasz Rzechuła',
      startSezon: 0,
      springCup: 23.98,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Piotr Bartniak',
      startSezon: 0,
      springCup: 21.18,
      pairCup: 0,
      nightCup: 19.9,
      summerBye: 11.23,
    },
    {
      name: 'Przemysław Wypyszewski',
      startSezon: 0,
      springCup: 20.52,
      pairCup: 0,
      nightCup: 26.25,
      summerBye: 0,
    },
    {
      name: 'Sylwester Laskowski',
      startSezon: 0,
      springCup: 20.39,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Paweł Bryś',
      startSezon: 0,
      springCup: 19.59,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Yevhen Nishchuk',
      startSezon: 0,
      springCup: 19.38,
      pairCup: 27.61,
      nightCup: 17.31,
      summerBye: 11.64,
    },
    {
      name: 'Michał Wojciechowski',
      startSezon: 0,
      springCup: 19.25,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Rafał Kozłowski',
      startSezon: 0,
      springCup: 14.5,
      pairCup: 0,
      nightCup: 0,
      summerBye: 5.75,
    },
    {
      name: 'Maciej Kwiatkowski',
      startSezon: 0,
      springCup: 13.65,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Marcin Kubalak',
      startSezon: 0,
      springCup: 12.64,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Robert Lipski',
      startSezon: 0,
      springCup: 7.77,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Juliusz Obuchowski',
      startSezon: 0,
      springCup: 7.6,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Przemysław Prajs',
      startSezon: 0,
      springCup: 7.5,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Zbigniew Matysiak',
      startSezon: 0,
      springCup: 3.48,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Maksymilian Karol',
      startSezon: 0,
      springCup: 0,
      pairCup: 38.38,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Paweł Jackowiak',
      startSezon: 0,
      springCup: 0,
      pairCup: 33.17,
      nightCup: 0,
      summerBye: 13.55,
    },
    {
      name: 'Łukasz Kwaśniewski',
      startSezon: 0,
      springCup: 0,
      pairCup: 28.29,
      nightCup: 37.23,
      summerBye: 0,
    },
    {
      name: 'Kacper Wolfram',
      startSezon: 0,
      springCup: 0,
      pairCup: 27.16,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Maksym Romaniuk',
      startSezon: 0,
      springCup: 0,
      pairCup: 21.6,
      nightCup: 39.4,
      summerBye: 7.9,
    },
    {
      name: 'Bartek Serwiński',
      startSezon: 0,
      springCup: 0,
      pairCup: 21.48,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Łukasz Srebny',
      startSezon: 0,
      springCup: 0,
      pairCup: 20.62,
      nightCup: 34.3,
      summerBye: 14.42,
    },
    {
      name: 'Dawid Krawczyk',
      startSezon: 0,
      springCup: 0,
      pairCup: 19.99,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Damian Kamiński',
      startSezon: 0,
      springCup: 0,
      pairCup: 19.32,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Mariusz Płaczkowski',
      startSezon: 0,
      springCup: 0,
      pairCup: 14.12,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Adrian Szymańczak',
      startSezon: 0,
      springCup: 0,
      pairCup: 13.68,
      nightCup: 2,
      summerBye: 0,
    },
    {
      name: 'Jakub Kucharski',
      startSezon: 0,
      springCup: 0,
      pairCup: 12.04,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Tomasz Gabeński',
      startSezon: 0,
      springCup: 11.8,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Mateusz Malczyk',
      startSezon: 0,
      springCup: 0,
      pairCup: 11.72,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Kamil Szczepaniak',
      startSezon: 0,
      springCup: 0,
      pairCup: 10.7,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Karol Ropel',
      startSezon: 0,
      springCup: 0,
      pairCup: 7.43,
      nightCup: 27.12,
      summerBye: 0,
    },
    {
      name: 'Daniel Milczarek',
      startSezon: 0,
      springCup: 0,
      pairCup: 4.72,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Michał Puk',
      startSezon: 0,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Michał Jakubczyk',
      startSezon: 0,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0,
    },
    {
      name: 'Radosław Waldowski',
      startSezon: 0,
      springCup: 0,
      pairCup: 0,
      nightCup: 20.36,
      summerBye: 0,
    },
    {
      name: 'Adam Rochnowski',
      startSezon: 0,
      springCup: 0,
      pairCup: 0,
      nightCup: 29.76,
      summerBye: 3.45,
    },
    {
      name: 'Artur Klawczyński',
      startSezon: 0,
      springCup: 0,
      pairCup: 0,
      nightCup: 21.15,
      summerBye: 11.98,
    },
    {
      name: 'Filip Kurowski',
      startSezon: 0,
      springCup: 0,
      pairCup: 0,
      nightCup: 18.75,
      summerBye: 0,
    },
    {
      name: 'Łukasz Lemańczyk',
      startSezon: 0,
      springCup: 0,
      pairCup: 0,
      nightCup: 18.5,
      summerBye: 0,
    },
    {
      name: 'Marek Rońda',
      startSezon: 0,
      springCup: 0,
      pairCup: 0,
      nightCup: 16.7,
      summerBye: 0,
    },
    {
      name: 'Oliwier Przydatek',
      startSezon: 0,
      springCup: 0,
      pairCup: 0,
      nightCup: 13.82,
      summerBye: 0,
    },
    {
      name: 'Leszek Agatowski',
      startSezon: 0,
      springCup: 0,
      pairCup: 0,
      nightCup: 9.72,
      summerBye: 0,
    },
    {
      name: 'Bartek Lewandowski',
      startSezon: 0,
      springCup: 0,
      pairCup: 0,
      nightCup: 9.65,
      summerBye: 0,
    },
    {
      name: 'Filip Krajewski',
      startSezon: 0,
      springCup: 0,
      pairCup: 0,
      nightCup: 17.11,
      summerBye: 0,
    },
    {
      name: 'Leszek Broński',
      startSezon: 0,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 9.25,
    },
    {
      name: 'Maksymilian Ropel',
      startSezon: 0,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 31.7,
    },
    {
      name: 'Rafał Bociek',
      startSezon: 0,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 16.55,
    },
    {
      name: 'Patryk Tyborczyk',
      startSezon: 0,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 13.22,
    },
    {
      name: 'Bartosz Wawrzyniak',
      startSezon: 0,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 7.02,
    },
    {
      name: 'Sebastian Adamski',
      startSezon: 0,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 5.66,
    },
    {
      name: 'Paweł Dzierżewski',
      startSezon: 0,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 20.43,
    },
    {
      name: 'Szymon Germiński',
      startSezon: 0,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 1.58,
    },
    {
      name: 'Krzysztof Beczak',
      startSezon: 0,
      springCup: 0,
      pairCup: 0,
      nightCup: 0,
      summerBye: 0.3,
    },
  ];

  return { players };
};

export default CupResults;
